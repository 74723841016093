export const toTitleCase = (string) => {
  if (string) {
    var sentence = string.toLowerCase().split(' ');
    for (var i = 0; i < sentence.length; i++) {
      if (sentence[i] != '')
        sentence[i] = sentence[i][0].toString().toUpperCase() + sentence[i].slice(1);
    }
    return sentence.join(' ');
  }
};

export async function HttpRequest(URL, Method, Body, BaseUrl) {
  //let BaseURL = BaseUrl ? BaseUrl : `http://localhost:9000/`;
  let BaseURL = BaseUrl ? BaseUrl : `https://greyapis-6udq.vercel.app/`;
  let response;

  if (Method === 'POST' || Method === 'PUT') {
    response = await fetch(`${BaseURL}${URL}`, {
      method: Method,
      body: JSON.stringify(Body),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  } else if (Method === 'DELETE') {
    response = await fetch(`${BaseURL}${URL}`, {
      method: 'DELETE',
    });
    return response.json();
  } else {
    response = await fetch(`${BaseURL}${URL}`, {
      method: 'GET',
    });
  }
  //  console.log(`${BaseURL}${URL}`);
  return await response.json();
}

export async function HttpRequestImage(URL, method, Body, BaseUrl) {
  let response = await fetch(`${BaseUrl}${URL}`, {
    method: method,
    body: Body,
    accept: 'application/json',
  });
  return await response.json();
}

export const amountInWords = (n) => {
  if (n) {
    var string = n.toString(),
      units,
      tens,
      scales,
      start,
      end,
      chunks,
      chunksLen,
      chunk,
      ints,
      i,
      word,
      words,
      and = 'and';

    /* Remove spaces and commas */
    string = string.replace(/[, ]/g, '');

    /* Is number zero? */
    if (parseInt(string) === 0) {
      return 'zero';
    }

    /* Array of units as words */
    units = [
      '',
      'one',
      'two',
      'three',
      'four',
      'five',
      'six',
      'seven',
      'eight',
      'nine',
      'ten',
      'eleven',
      'twelve',
      'thirteen',
      'fourteen',
      'fifteen',
      'sixteen',
      'seventeen',
      'eighteen',
      'nineteen',
    ];

    /* Array of tens as words */
    tens = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];

    /* Array of scales as words */
    scales = [
      '',
      'thousand',
      'million',
      'billion',
      'trillion',
      'quadrillion',
      'quintillion',
      'sextillion',
      'septillion',
      'octillion',
      'nonillion',
      'decillion',
      'undecillion',
      'duodecillion',
      'tredecillion',
      'quatttuor-decillion',
      'quindecillion',
      'sexdecillion',
      'septen-decillion',
      'octodecillion',
      'novemdecillion',
      'vigintillion',
      'centillion',
    ];

    /* Split user argument into 3 digit chunks from right to left */
    start = string.length;
    chunks = [];
    while (start > 0) {
      end = start;
      chunks.push(string.slice((start = Math.max(0, start - 3)), end));
    }

    /* Check if function has enough scale words to be able to stringify the user argument */
    chunksLen = chunks.length;
    if (chunksLen > scales.length) {
      return '';
    }

    /* Stringify each integer in each chunk */
    words = [];
    for (i = 0; i < chunksLen; i++) {
      chunk = parseInt(chunks[i]);

      if (chunk) {
        /* Split chunk into array of individual integers */
        ints = chunks[i].split('').reverse().map(parseFloat);

        /* If tens integer is 1, i.e. 10, then add 10 to units integer */
        if (ints[1] === 1) {
          ints[0] += 10;
        }

        /* Add scale word if chunk is not zero and array item exists */
        if ((word = scales[i])) {
          words.push(word);
        }

        /* Add unit word if array item exists */
        if ((word = units[ints[0]])) {
          words.push(word);
        }

        /* Add tens word if array item exists */
        if ((word = tens[ints[1]])) {
          words.push(word);
        }

        /* Add 'and' string after units or tens integer if: */
        if (ints[0] || ints[1]) {
          /* Chunk has a hundreds integer or chunk is the first of multiple chunks */
          if (ints[2] || i + 1 > chunksLen) {
            words.push(and);
          }
        }

        /* Add hundreds word if array item exists */
        if ((word = units[ints[2]])) {
          words.push(word + ' hundred');
        }
      }
    }

    return words.reverse().join(' ');
  }
};

export const datediff = (first, second) => {
  return Math.round((second - first) / (1000 * 60 * 60 * 24));
};

export const datediffMonths = (d1, d2) => {
  if (d1 && d2) {
    var ydiff = d2.getYear() - d1.getYear();
    var mdiff = d2.getMonth() - d1.getMonth();
    var ddiff = 1 + d2.getDate() - d1.getDate();

    var newdate = ydiff * 12 + mdiff + '.' + ddiff;
    return Math.ceil(newdate);
  }
};

export const datediffYears = (dateold, datenew) => {
  var ynew = datenew.getFullYear();
  var mnew = datenew.getMonth();
  var dnew = datenew.getDate();
  var yold = dateold.getFullYear();
  var mold = dateold.getMonth();
  var dold = dateold.getDate();
  var diff = ynew - yold;
  if (mold > mnew) diff--;
  else {
    if (mold == mnew) {
      if (dold > dnew) diff--;
    }
  }
  return diff;
};

export const HandleOnChangeValue = async (completeObj, setObject, funcList = null) => {
  let [key, value] = Object.entries(completeObj)[0];
  // setObject((prev) => {
  //   return { ...prev, ...completeObj };
  // });
  setObject(completeObj);
  funcList?.forEach((func) => func);
};

export const GetAndSetList = async (request, setItem, name) => {
  if (request) {
    let objectJson = await HttpRequest(request);
    //  console.log('getandsetlist ', objectJson)
    if (!name) {
      setItem(objectJson);
    } else {
      setItem((prev) => {
        return { ...prev, [name]: objectJson };
      });
    }
  } else {
    setItem((prev) => {
      return { ...prev, [name]: null };
    });
  }
};

export const GetAndSetValue = async (request, setItem, name, setObject = {}) => {
  if (request) {
    let objectJson = await HttpRequest(request);
    if (objectJson?.length > 0) {
      let result = objectJson[0];
      let key = Object.keys(result)[0];
      let value = result[key];
      setObject({ [name]: value });
      setItem((prev) => {
        return { ...prev, [name]: value };
      });
    }
  } else {
    setItem((prev) => {
      return { ...prev, [name]: null };
    });
  }
};

export const SelectPaymentMode = async (value, setItem) => {
  switch (value) {
    case 'OnlineTransfer':
      setItem((prev) => {
        return { ...prev, showAccount: true, showChequeDetails: false };
      });
      break;
    case 'Cheque':
      setItem((prev) => {
        return { ...prev, showAccount: true, showChequeDetails: true };
      });
      break;
    default:
      setItem((prev) => {
        return { ...prev, showAccount: false, showChequeDetails: false };
      });
      break;
  }
};

export const arabic = /[\u0600-\u06FF]/;
export const GetVoucherNumber = async (parameter, setObject, table, EditNumber) => {
  if (!parameter) return;
  let params = parameter ? `/${parameter}` : '';
  let form = table
    ? table
    : window.location.pathname.split('/')[2].slice(0, -1).replaceAll('-', '');
  let editAction = !isNaN(window.location.pathname.split('/')[3]);
  let objectJson = await HttpRequest(
    `getVoucherNumber/${form}/${localStorage.getItem('username')}/${localStorage.getItem(
      'client'
    )}${params}`,
    'GET'
  );

  if (objectJson?.length > 0) {
    const { Series, Number } = objectJson[0];
    setObject &&
      HandleOnChangeValue(
        { Series, ...(editAction ? { Number: EditNumber } : { Number }) },
        setObject
      );
    return objectJson[0];
  }

  //setItem && setItem(Series?.concat('', Number));
};

export const SaveFormOnClick = async (form, selectedValue, idForm, journalID) => {
  let entity = await HttpRequest(`getRecordById/${idForm}/${selectedValue?.ID}`);
  let lead = await HttpRequest(
    `getCustomPostBackItem/leads/${localStorage.getItem('username')}/${localStorage.getItem(
      'client'
    )}/${selectedValue?.ID}`,
    'GET'
  );

  let dueDate = new Date();
  if (lead.length > 0) {
    dueDate.setDate(dueDate.getDate() + parseInt(lead[0]?.Paymentterms ?? 0));
  }

  let voucherNumber = await GetVoucherNumber(selectedValue?.Name, null, form.slice(0, -1));

  let { Rent, TotalRent } = entity[0];

  let invoice = {
    Date: new Date().toISOString(),
    Lead: selectedValue?.ID,
    ...voucherNumber,
    DueDate: dueDate?.toISOString(),
    Reference: journalID,
    TotalQuantity: 1,
    SubTotal: Rent,
    TotalTax: 0,
    TotalDiscount: 0,
    GrandTotal: TotalRent,
  };

  let id = await HttpRequest(
    `${form}/${localStorage.getItem('client')}/${localStorage.getItem('username')}`,
    'POST',
    invoice
  );

  let response = await HttpRequest(
    `${form.slice(0, -1)}details/${localStorage.getItem('client')}/${localStorage.getItem(
      'username'
    )}`,
    'POST',
    {
      Item: 0,
      Description: 'Rent Charged',
      Quantity: 1,
      Unit: 0,
      Price: Rent,
      DiscountPercentage: 0,
      Discount: 0.0,
      Tax: 0,
      TaxAmount: 0,
      Total: TotalRent,
      [form.slice(0, -1)]: id,
    }
  );

  return id;
};

export function FormatDate(oldDate, setDate) {
  let newDate =
    oldDate &&
    new Date(oldDate)
      ?.toDateString()
      ?.replace(/^\S+\s/, '')
      ?.split(' ');
  newDate?.splice(2, 0, ',');
  newDate = newDate?.join(' ');
  setDate(newDate);
}

export function getClientName() {
  const company = localStorage.getItem('companies')
    ? JSON.parse(localStorage.getItem('companies')).filter(
        (record) => record.ID == localStorage.getItem('client')
      )
    : null;
  if (company) {
    return company[0]?.Name ?? 0;
  }
}

export const getTaxCategory = (
  selectedValue,
  Amount,
  setTaxCategory,
  setTaxAmount,
  setTotalAmount,
  setTaxType,
  setTaxValue,
  setObject,
  object,
  name,
  tax,
  total
) => {
  HttpRequest(`getRecordById/taxCategories/${selectedValue?.ID}`)
    .then((response) => response)
    .then((json) => {
      let totaltaxamount;
      if (json[0].Type == 'Amount') {
        totaltaxamount = formatdecimals(parseFloat(json[0].Value));
      } else {
        totaltaxamount = formatdecimals(
          parseFloat(Amount) * (parseFloat(json[0].Value) / parseFloat(100))
        );
      }

      setObject({
        ...object,
        [name]: selectedValue?.ID,
        [tax]: totaltaxamount,
        [total]: formatdecimals(parseFloat(Amount) + parseFloat(totaltaxamount)),
      });

      setTaxCategory(json[0].Id);
      setTaxType(json[0].Type);
      setTaxValue(json[0].Value);
      setTaxAmount(totaltaxamount);
      setTotalAmount(formatdecimals(parseFloat(Amount) + parseFloat(totaltaxamount)));
    });
};

export const getBase64 = (sellername, vatregistration, timestamp, invoiceamount, vatamount) => {
  var ltr = String.fromCharCode(0x200e);
  var seller = getTlvVAlue('1', sellername);
  var vatno = getTlvVAlue('2', vatregistration);
  var time = getTlvVAlue('3', timestamp);
  var invamt = getTlvVAlue('4', invoiceamount);
  var vatamt = getTlvVAlue('5', vatamount);
  var result = seller.concat(vatno, time, invamt, vatamt);
  return btoa(String.fromCharCode.apply(null, result));
};

export const getTlvVAlue = (tagnums, tagvalue) => {
  var tagnums_array = [tagnums];
  var tagvalue1 = tagvalue;
  var tagnum = tagnums_array.map(Number);
  var tagvalueb = new TextEncoder().encode(tagvalue1);
  var taglengths = [tagvalueb.length.toString()];
  var tagvaluelengths = taglengths.map(Number);
  var tlvVAlue = new Uint8Array(tagnum.concat(tagvaluelengths, Array.from(tagvalueb)));
  return Array.from(tlvVAlue); // convert to a regular array
};

export const addMinutesToDate = (minutesCal, startDate) => {
  let minutes = minutesCal;
  if (startDate) {
    let now = new Date(startDate);
    now.setMinutes(now.getMinutes() + minutes);
    now = new Date(now);
    return now;
  }
};

export const addDateToMinutes = (startDateCal, endDateCal) => {
  let startDate = startDateCal;
  let endDate = endDateCal;
  let dateDiff = Math.abs(new Date(endDate) - new Date(startDate)) / 1000;
  let duration = Math.floor(dateDiff / 60);
  return duration;
};

export const formatdecimals = (number) => {
  let decimal = localStorage.getItem('decimals');
  let decimalValue = 0;
  if (number) {
    decimalValue = parseFloat(number).toFixed(decimal);
    var parts = decimalValue.toString().split('.');
    const numberPart = parts[0];
    const decimalPart = parts[1];
    const thousands = /\B(?=(\d{3})+(?!\d))/g;
    return numberPart.replace(thousands, ',') + (decimalPart ? '.' + decimalPart : '');
  } else {
    decimalValue = parseFloat(number).toFixed(decimal);
    return decimalValue;
  }
};

export const updateCell = async (
  column,
  value,
  formInfo,
  setObjectEdit,
  tbldata,
  setTbldata,
  dropdown,
  dropdownVal
) => {
  let valueNew = value ? `/${value}` : '';
  let response = await HttpRequest(
    `https://greyapis-6udq.vercel.app/${formInfo?.form}/${formInfo?.id}/${column}${valueNew}`,
    {
      method: 'PATCH',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    }
  );

  let json = await response.json();
  // console.log(json)
  if (!isNaN(json)) {
    setObjectEdit((prev) => {
      return { ...prev, [column]: value };
    });
    let newGridData = tbldata?.map((row) => {
      if (row?.ID == formInfo?.id) {
        for (const key in row) {
          if (
            key?.replaceAll('_', '')?.toUpperCase() == column.toUpperCase() ||
            column?.toUpperCase().includes(key?.toUpperCase())
          ) {
            if (dropdown) {
              row[key] = dropdownVal;
            } else {
              row[key] = value;
            }

            return row;
          }
        }
        return row;
      } else {
        return row;
      }
    });
    setTbldata(newGridData);
  }
};
